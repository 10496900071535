<template>
  <div class="ma-4">
    <v-btn x-large class="mb-4 primary" @click="download">
      <v-icon left> $download </v-icon>
      Download as .txt
    </v-btn>
    <div class="pa-2 darken_gray">
      <div v-for="(value, key) in userBrowserSettings" :key="key">
        <pre class="user-settings wrap-long-text">{{ key }}: {{ value }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserBrowserSettings",
  data() {
    return {
      userBrowserSettings: {},
    };
  },
  created() {
    this.fetchUserBrowserSettings();
  },
  methods: {
    fetchUserBrowserSettings() {
      const tenant = localStorage.tenant;
      this.userBrowserSettings = {
        location: location,
        tenant: localStorage.tenant,
        version: window.APP_VERSION,
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.languages,
        online: navigator.onLine,
        hardwareConcurrency: navigator.hardwareConcurrency,
        deviceMemory: navigator.deviceMemory,
        cookieEnabled: navigator.cookieEnabled,
        devicePixelRatio: window.devicePixelRatio,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        windowResolution: `${window.screen.width}x${window.screen.height}`,
        screenResolution: `${window.innerWidth}x${window.innerHeight}`,
        storage: {
          locale: localStorage.locale,
          calendarCurrentView: localStorage.calendarCurrentView,
          isUserLogged: localStorage.isUserLogged,
          [tenant + "_" + "stepperIndex"]:
            localStorage[tenant + "_" + "stepperIndex"],
          [tenant + "_" + "cTabsState"]:
            localStorage[tenant + "_" + "cTabsState"],
          [tenant + "_" + "user_id"]: localStorage[tenant + "_" + "user_id"],
          [tenant + "_" + "cTabsState"]:
            localStorage[tenant + "_" + "cTabsState"],
          [tenant + "_" + "TableFilters"]:
            localStorage[tenant + "_" + "TableFilters"],
          [tenant + "_" + "TableOptions"]:
            localStorage[tenant + "_" + "TableOptions"],
          [tenant + "_" + "calendarSelectedEmployees"]:
            localStorage[tenant + "_" + "calendarSelectedEmployees"],
          [tenant + "_" + "categoriesExpandStatusV8"]:
            localStorage[tenant + "_" + "categoriesExpandStatusV8"],
        },
      };
    },
    download() {
      const data = JSON.stringify(this.userBrowserSettings, null, 2);
      const blob = new Blob([data], { type: "text/plain" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download =
        (localStorage.tenant || "notLogged") + "_userBrowserSettings.txt";
      link.click();

      URL.revokeObjectURL(url);
    },
  },
};
</script>
<style scoped>
.user-settings {
  white-space: pre-wrap;
}
.wrap-long-text {
  word-wrap: break-word;
}
</style>
